<template>
	<div class="page">
		<!-- 音频 -->
		<audio controls ref="commonaudio" class="disnone">
			<source src="" />
		</audio>
		<!-- 音频 -->
		<div class="w680">
			<topHeader @goBack="toHome()"></topHeader>
			<div class="top-title">
				<div class="line"></div>
				<span>单词拼写</span>
			</div>
			<!-- 卡片 -->
			<div class="word-panel">
				<div class="spell-itembox">
					<div class="spell-item" v-for="(item, index) in wordList" :key="index">
						<div class="linsten-btn" @click="playAudio(item.phAmMp3)">
							<img src="@/assets/static/linsten.png" mode="" />
						</div>
						<div class="word-desc clamp" v-if="item.wordTpye.length>0">
						    <span>{{ item.wordTpye[0].part }}</span>
						    <span class="desc2" v-for="(mitem, mindex) in item.wordTpye[0].means"
						    	:key="mindex">{{ mitem }}<span v-html="'&nbsp;'"></span>
						    </span>
						</div>
						<el-input class="input-word" :class="{'error': wordListValue[index].step ==2,'right': wordListValue[index].step ==1,
					    }" v-model="wordListValue[index].keyword" @focus="wordListValue[index].step= 0" placeholder="输入单词"></el-input>
						<!-- 答案 -->
						<div v-if="wordListValue[index].step ==2" class="answer">
							正确答案：{{item.word}}
						</div>
					</div>
				</div>
				<div class="sub-btn" @click="handleAnswer">
					{{ isShowAnswer ? "再次验证" : "提交验证答案" }}
				</div>
			</div>

			<!-- 完美弹窗 -->
			<el-dialog :show-close="false" width="539px" top="2vh" :visible.sync="learnFinish">
				<img src="@/assets/static/prefect.png" alt="" />
				<div class="dialog-text1">perfect</div>
				<div class="dialog-text2">完成{{wordList.length}}个单词拼写</div>
				<div class="dialog-line"></div>
				<div class="dialog-text3">单词拼写统计</div>
				<div class="dialog-word-list">
					<div class="dialog-word-item" v-for="(item, index) in wordList" :key="index">
						<div>{{item.word}}</div>
						<!-- <div>>{{item.cuonum}}次</div> -->
					</div>
				</div>

				<div class="to-home" @click="toHome()">返回首页</div>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	import topHeader from "@/components/topHeader/topHeader.vue";
    //引入状态管理
	import {mapState} from 'vuex'
	export default {
		components: {
			topHeader,
		},
		data() {
			return {
				learnFinish: false, // 是否学完了
				isShowAnswer: false, // 是否提交过验证
				wordList:[],//单词列表
				wordListValue:[],//新建输入框，解决无法双向绑定问题
			};
		},
		//状态值
		computed: {
			...mapState(['studyingData']),
		},
		created() {
			//console.log(this.studyingData)
			var wordList = this.shuffleArray(this.studyingData.successwords)
			var wordListValue = []
			wordList.forEach((item, index) => {
				item["cuowunum"] = 0
				item["step"] = 0 //步骤 0是未开始， 1是答对 2是答错
				item["wordTpye"] = []
				item["keyword"] = ""
				try{
					item["wordTpye"] =  item.definitionList || JSON.parse(item.definition)
				}catch(e){
					item["wordTpye"] = []
				}
				wordListValue.push({
					id:item.id,
					word:item.word,
					keyword:"",
					step:0,
					cuowunum:0
				})
			});
			this.wordList = wordList
			this.wordListValue = wordListValue
		},
		methods: {
			//打乱顺序
			shuffleArray(array) {
				for (let i = array.length - 1; i > 0; i--) {
				  // 生成[0, i]范围内的随机索引
				  const j = Math.floor(Math.random() * (i + 1));
				  // 交换array[i]和array[j]
				  [array[i], array[j]] = [array[j], array[i]];
				}
				return array;
			},
			//验证答案
			handleAnswer(){
				var _this = this
				//console.log(this.wordListValue)
				var newrecords = this.wordListValue.filter(item => !item.keyword);
				if(newrecords.length>0){
					this.$message.error("请全部拼写完单词！")
					return false;
				}
				newrecords = this.wordListValue.filter(item => item.step==2);
				if(newrecords.length>0){
					this.$message.error("请把拼写错误的单词请重新拼写！")
					return false;
				}
				var ifcuowu = true
				this.wordListValue.forEach((item, index) => {
					let word = item.word.replace(/[^a-zA-Z]/g, "").toLowerCase()
					let keyword = item.keyword.replace(/[^a-zA-Z]/g, "").toLowerCase()
					if(keyword==word){
						item.step = 1
					}else{
						item.cuowunum +=1
						item.step = 2
						ifcuowu = false
					}
				});
				if(ifcuowu){
					//全部拼写完成
					this.learnFinish = true
				}else{
					//答题过，错误了
					this.isShowAnswer = true
				}
				
			},
			toHome() {
				this.$router.push({
					name: "home",
				});
			},
			//播放音频
			playAudio(src) {
				var _this = this
				try{
					_this.$refs.commonaudio.src = src;
					_this.$nextTick(res => {
					    _this.$refs.commonaudio.load()
					    _this.$refs.commonaudio.play()
					})
				}catch(e){
					//TODO handle the exception
				}
			},
		},
	};
</script>
<style lang="scss" scoped>
	.dialog-text1 {
		font-weight: bold;
		margin-top: 10px;
		color: #222222;
		font-size: 28px;
	}

	.dialog-text2 {
		margin-top: 16px;
		color: #666666;
		font-size: 20px;
	}

	.dialog-text3 {
		margin: 16px 0;
		color: #5c3704;
		font-weight: bold;
		font-size: 16px;
	}

	.dialog-line {
		border-bottom: 1px dashed #dbdbdb;
		margin-top: 16px;
		width: 311px;
	}
	.dialog-word-list{
		max-height: 300px;
		overflow-y: auto;
	}
	.dialog-word-item {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 12px;
		width: 311px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.to-home {
		background: #67adff;
		cursor: pointer;
		width: 146px;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #fff;
		margin-top: 35px;
		border-radius: 8px;
		font-weight: bold;
		font-size: 14px;
		transition: all 0.3s;

		&:hover {
			opacity: 0.8;
		}
	}

	/deep/ .el-dialog {
		background: unset !important;
	}

	/deep/ .el-dialog__header {
		display: none;
	}

	/deep/ .el-dialog__body {
		background: #fbf7f5;
		border-radius: 16px;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 20px;
	}

	.top-title {
		font-size: 20px;
		color: #5c3704;
		font-weight: bold;
		margin-top: 40px;
		display: flex;
		align-items: center;

		.line {
			height: 14px;
			width: 2px;
			background: #51d9a5;
			margin-right: 16px;
		}
	}

	.word-panel {
		border-radius: 12px;
		min-height: 400px;
		padding: 24px;
		margin-top: 16px;
		margin-bottom: 40px;
		position: relative;
		border: 1px solid #e5e5e5;
		.spell-itembox{
			max-height: 558px;
			overflow-y: auto;
		}
		.spell-item {
			display: flex;
			align-items: center;
			margin-top: 20px;
			&:first-child {
				margin-top: 0;
			}
			.word-desc {
				margin-left: 6px;
				font-size: 14px;
				width: 188px;
				.desc2{
					margin-left: 4px;
				}
			}
			.answer {
				margin-left: auto;
				background: #e4ffe3;
				color: #51d9a5;
				padding: 6px 8px;
				font-size: 14px;
				border-radius: 4px;
				cursor: default;
			}
			.linsten-btn {
				border-radius: 20px;
				border: 1px solid #e0e0e0;
				background: #fff;
				box-shadow: 0px 4px 5.2px 0px rgba(0, 0, 0, 0.07);
				height: 24px;
				width: 32px;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;

				&:hover {
					transform: scale(1.05);
				}

				img {
					width: 16px;
					height: 16px;
				}
			}
			.input-word {
				width: 200px;
				font-size: 16px;
				color: #222222;
				margin-left: 18px;
			}

			/deep/ .el-input__inner {
				border-radius: 0;
				border: unset;
				border-bottom: 1px solid #e0e0e0 !important;
			}

			.error {
				/deep/.el-input__inner {
					color: #ff0000;
				}
			}

			.right {
				/deep/.el-input__inner {
					color: #51d9a5;
				}
			}
		}

		.sub-btn {
			background: #67adff;
			width: 146px;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 38px 150px 0;
			color: #fff;
			border-radius: 8px;
			font-weight: bold;
			font-size: 14px;
			transition: all 0.3s;
			cursor: pointer;

			&:hover {
				opacity: 0.8;
			}
		}
	}
</style>